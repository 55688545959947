// Imports
// if any goes here

document.addEventListener('turbolinks:load', () => {
	// Begin Mobile Small devices menubar
	var toggle = document.getElementById("nav-toggler");
	var topNav = document.getElementById("navigation");

	// Listen for click event on toggle var
	toggle.addEventListener(
		"click",
		function () {
			// console.log("toggle clicked");
			// toggle class "hidden" on topNav var
			topNav.classList.toggle("hidden");
		},
		false
	);
	// End Mobile Small devices menubar

	// Begin Toastr Options
	toastr.options = {
		preventDuplicates: true,
	};
	// End Toastr Options
})

//
// Global Functions out of Turbolinks
//


