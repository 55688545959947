// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// https://stackoverflow.com/questions/59703024/rails-6-rails-not-defined
import Rails from "@rails/ujs";
Rails.start();
window.Rails = Rails;

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// 3rd party
import "@fortawesome/fontawesome-free/js/all";
import LocalTime from "local-time";
LocalTime.start();
global.toastr = require("toastr");
// global.Trix = require("trix");
require("trix");
require("@rails/actiontext");
const Choices = require("choices.js"); // https://github.com/jshjohnson/Choices
require("chartkick")
require("chart.js")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "stylesheets/application";
import "packs/atlas";

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener("turbolinks:load", () => {
  // Choices
  // https://github.com/jshjohnson/Choices
  var programExerciseList = document.getElementById("program_exercise_exercise_id");
  if (programExerciseList) {
    programExerciseList = new Choices(programExerciseList, {
      // 
    });
  }
  var logicSymptomList = document.getElementById("logic_symptom_id");
  if (logicSymptomList) {
    logicSymptomList = new Choices(logicSymptomList, {
      // 
    });
  }
  var logicPainLocationList = document.getElementById("logic_pain_location_id");
  if (logicPainLocationList) {
    logicPainLocationList = new Choices(logicPainLocationList, {
      // 
    });
  }
  var logicExerciseList = document.getElementById("logic_exercise_exercise_id");
  if (logicExerciseList) {
    logicExerciseList = new Choices(logicExerciseList, {
      // 
    });
  }

  // var responseSymptomList = document.getElementById("response_symptom_id");
  // if (responseSymptomList) {
  //   responseSymptomList = new Choices(responseSymptomList, {
  //     // 
  //   });
  // }
  // var responsePainLocationList = document.getElementById("response_pain_location_ids");
  // if (responsePainLocationList) {
  //   responsePainLocationList = new Choices(responsePainLocationList, {
  //     // 
  //   });
  // }
});

import "controllers"

require("@rails/actiontext")